.publications{
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 300px;
    margin-top: 100px;
    margin-bottom: 100px;
}

@media screen and (min-width: 720px) {
    .publications {
        width: 650px; /* size for 1080px screen */
    }
}

@media screen and (min-width: 1080px) {
    .publications {
        width: 1000px; /* size for 1080px screen */
    }
}

.title{
    display: flex;
    font-family: 'inter-bold';
    font-size: 25px;
    margin-bottom: 30px;
}

.categories{
    display: flex;
    font-family: 'inter-regular';
    font-size: 15px;
    margin-bottom: 40px;
    gap: 10px;
}

.category{
    display: flex;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 2px;
    transition: all 0.2s ease;
}

.pub_set{
    display: flex;
    justify-content: space-between;
}

.pub{
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-bottom: 35px;
}

.pubtitle{
    display: flex;
    font-family: 'inter-bold';
    font-size: 17px;
    margin-bottom: 7px;
    line-height: 23px;
}

.authors{
    display: inline;
    font-family: 'inter-regular';
    color: #2A2A2A;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 4px;
}

.venue{
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #2A2A2A;
    margin-bottom: 4px;
}

.conference{
    display: flex;
    font-family: 'inter-regular';
    font-style: italic;
    color: #2A2A2A;
    margin-right: 6px;
}

.year{
    display: flex;
    font-family: 'inter-semibold';
    color: #3c3c3c;
    font-size: 15px;
    line-height: 25px;
    margin-right: 8px;
}

.others{
    display: flex;
    gap: 6px;
    margin-top: 5px;
    color: #000000;
}

.awards{
    display: flex;
    align-items: center;
    font-family: 'inter-bold';
    color: #0057FF;    
    font-size: 15px;
    padding: 2px 2px;
    background-color: rgba(0, 87, 255, 0.08);
    border-radius: 4px;
}

.doi, .pdf, .video, .Arxiv {
    display: flex;
    font-family: 'inter-regular';
    font-size: 13px;
    background-color: #f4f4f4;
    /* border: 1px solid black;  */
    padding: 3px 6px;
    transition: all 0.2s ease;
}

.others .doi a,
.others .pdf a,
.others .video a,
.others .Arxiv a {
    color: rgb(74, 74, 74);
    text-decoration: none;
}

.others a:hover {
    color: #0057FF;
    font-family: 'inter-semibold';
}
/* .doi:hover, .pdf:hover, .video:hover, .Arxiv:hover {
  display: flex;
  font-family: 'inter-semibold';
  font-size: 13px;
  background-color: white;
  border: 1px solid #0057FF; 
  padding: 3px 6px;
  transition: all 0.2s ease;
} */

.pub_year{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.year_info{
    display: flex;
    font-family: inter-semibold;
    font-size: 15px;
    margin-bottom: 8px;
}

.paper_type{
    display: flex;
    font-family: inter-light;
    font-size: 14px;
    /* font-style: italic; */
}

.author-highlight {
    font-family: 'inter-semibold';
    color: #2A2A2A;
    text-decoration: underline;
    text-decoration-color: #2A2A2A;
    text-decoration-style: underline;
}

.category.active {
    font-family: 'inter-semibold';
    color: #0057FF;
    background-color: rgba(0, 87, 255, 0.1);
}

.category:hover {
    background-color: rgba(0, 87, 255, 0.05);
}