.projects {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 300px;
    margin-top: 150px;
}

.explanation {
    display: flex;
    font-family: inter-regular;
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px;
}

.project_all {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start; /* Aligns children to the start */
    gap: 30px; /* Adjust as needed for spacing between children */
}

.project_each {
    display: flex;
    /* width: calc(33.333% - 20px); */
    flex-direction: column;
    width: 310px;
    margin-bottom: 50px;
}

.project_image {
    display: flex;
    margin-bottom: 20px;
}

.project_title {
    display: flex;
    font-family: inter-bold;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 15px;
}

.project_title:hover {
    display: flex;
    font-family: inter-bold;
    color: #0057FF;
    text-decoration: underline;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 15px;
}

.tag {
    display: flex;
    font-family: lato-bold;
    font-size: 13px;
    margin-bottom: 5px;
}

.project_description {
    display: flex;
    font-family: inter-regular;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
    margin-bottom: 15px;
}

.button-bar {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    /* padding-top: 20px; */
    /* padding: 10px; */
    /* margin-bottom: 15px; */
}

.button {
    border: 1.5px solid black;
    /* background-color: black; */
    padding: 5px 10px;
    text-align: center;
    font-family: inter-semibold;
    font-size: 12px;
    cursor: default; /* 버튼처럼 보이지만 클릭할 수 없음 */
    /* margin: 0 10px; */
    display: inline-block; /* 올바르게 정렬하기 위해 inline-block을 사용함 */
    margin-right: 10px;
    margin-bottom: 5px;
}

.related_pub {
    font-family: inter-semibold;
    font-size: 13px;
    margin-bottom: 17px;
    color: #0057FF;
    text-decoration: none;
}

.button:hover {
    border: 1.5px solid #0057FF;
    color: #0057FF;
    font-family: inter-semibold;
}

@media screen and (min-width: 720px) {
    .projects {
        width: 650px; /* size for 1080px screen */
    }
}

@media screen and (min-width: 1080px) {
    .projects {
        width: 1000px; /* size for 1080px screen */
    }
}